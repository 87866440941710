import {
  collection,
  query,
  getDoc,
  getDocs,
  orderBy,
  where,
  onSnapshot,
  limit,
  doc,
  startAfter,
} from 'firebase/firestore'
import { db } from '@/main'
import Resource from '@/data/tools/Resource'

export default class StoryDao{

  first: any
  last: any

  async getStories(callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, 'stories'),
        where('isDeleted', '==', false),
        where('isVisible', '==', true),
        orderBy('created', 'desc'),
        limit(9)
      )
      onSnapshot(q, (snap) => {
        this.last = snap.docs[snap.docs.length - 1]
        callback(Resource.success(snap.docs.map((doc) => doc.data())))
      })
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getFirstStory(callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, 'stories'),
        where('isDeleted', '==', false),
        where('isVisible', '==', true),
        orderBy('created', 'desc'),
        limit(1)
      )
      const snap = await getDocs(q)
      callback(Resource.success(snap.docs.map((doc) => doc.data())))
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getLastStory(callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, 'stories'),
        where('isDeleted', '==', false),
        where('isVisible', '==', true),
        orderBy('created', 'asc'),
        limit(1)
      )
      const snap = await getDocs(q)
      callback(Resource.success(snap.docs.map((doc) => doc.data())))
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getNextStories(callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, 'stories'),
        where('isDeleted', '==', false),
        where('isVisible', '==', true),
        orderBy('created', 'desc'),
        startAfter(this.last),
        limit(9)
      )
      onSnapshot(q, (snap) => {
        this.first = snap.docs[0]
        this.last = snap.docs[snap.docs.length - 1]
        callback(Resource.success(snap.docs.map((doc) => doc.data())))
      })
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getBackStories(callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, 'stories'),
        where('isDeleted', '==', false),
        where('isVisible', '==', true),
        orderBy('created', 'asc'),
        startAfter(this.first),
        limit(9)
      )
      onSnapshot(q, (snap) => {
        this.last = snap.docs[0]
        this.first = snap.docs[snap.docs.length - 1]
        const result = snap.docs.reverse()
        callback(Resource.success(result.map((doc) => doc.data())))
      })
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getStory(id: String, callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = doc(db, 'stories', `${id}`)
      const snap = await getDoc(q)
      callback(Resource.success(snap.data()))
    } catch (error) {
      callback(Resource.error(error))
    }
  }
}